<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <!-- <el-form-item label="公司总充值金额" prop="companyTotalRecharge">
        <el-input v-model="dataForm.companyTotalRecharge" :disabled="disabled" placeholder="公司总充值金额"></el-input>
      </el-form-item> -->

      <!-- <el-form-item label="余额" prop="balance">
        <el-input v-model="dataForm.balance" :disabled="disabled" placeholder="余额"></el-input>
      </el-form-item>
      <el-form-item label="本次充值时间" prop="thisTimeRecharge">
        <el-input v-model="dataForm.thisTimeRecharge" :disabled="disabled" placeholder="本次充值时间"></el-input> -->
      <!-- </el-form-item> -->
      <el-form-item label="公司id" prop="companyId">
        <el-input
          v-model="dataForm.companyId"
          :disabled="disabled"
          placeholder="公司id"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="公司名称" prop="companyName">
        <el-input v-model="dataForm.companyName" :disabled="disabled" placeholder="公司名称(如果公司存在则不需要输入公司名称)"></el-input>
      </el-form-item> -->
      <el-form-item label="公司本次充值金额" prop="rechargeThistime">
        <el-input
          v-model="dataForm.rechargeThistime"
          :disabled="disabled"
          placeholder="该公司本次充值金额"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="已分配金额" prop="allocatedAccount">
        <el-input v-model="dataForm.allocatedAccount" :disabled="disabled" placeholder="已分配金额"></el-input>
      </el-form-item> -->
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { keys, pick } from 'lodash';

export default {
  data() {
    return {
      disabled: false,
      visible: false,
      dataForm: {
        id: 0,
        companyTotalRecharge: '',
        rechargeThistime: '',
        balance: '',
        thisTimeRecharge: '',
        companyId: '',
        companyName: '',
        allocatedAccount: '',
      },
      dataRule: {
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
      },
    };
  },
  methods: {
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/tc/companyrechargedetails/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = pick(
                data.companyrechargedetails,
                keys(this.dataForm),
              );
            }
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/tc/companyrechargedetails/${
              !this.dataForm.id ? 'save' : 'update'
            }`,
            method: 'post',
            data: this.dataForm,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },
  },
};
</script>
